import { encode } from "base-64";
import { getActiveCategories, getNarratedContentCategories, getMusicCategories } from './DataFilters';

  const secure = {
     secureUser: process.env.REACT_APP_FILES_STORAGE_USER,
     securePass: process.env.REACT_APP_FILES_STORAGE_PASS,
     get authorization() {
        // Calculate the authorization property using the encode function
        return 'Basic ' + encode(this.secureUser + ':' + this.securePass);
      },
  }
  
  const protectedPaths = {
    
     serverUrl : process.env.REACT_APP_CONTENT_SERVER_URL,
     prod: "/prod",
     nonProtectedPath: "/nonprotected",
     protectedPath : "/protected",


     protectedAudioContentPath : "/audio",
     protectedAudioTracksContent : "/tracks/",
     protectedAudioCategorizedContent : "/categorizedContent/",

     protectedImageContentPath : "/image",
     nonProtectedImageContentPath : "/image",
     protectedImageAlbumsContent : "/albums/",
     protectedImageCategoriesContent: "/categories/",
     protectedImageLanguagesContent: "/languages/",
     nonProtectedSocialMediaIcons: "/socialmedia/",

     nonProtectedVideoContentPath : "/video",
     nonProtectedLogoImagePath : "/logo/",
     nonProtectedVideoLogoPath : "/logo",
     nonProtectedAnimaterdVideoLogoPath : "/animated/",
     
     protectedDataContentPath : "/data",
     protectedAllDataContent : "/DATA_All.json",
     
     protectedDataTracksContent : "/tracks/",
     protectedDataCategoriesContent : "/categories/",

      get protectedAllDataUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedDataContentPath + this.protectedAllDataContent;
      },

      get protectedTracksUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedAudioContentPath + this.protectedAudioTracksContent;
      },

      get protectedCategorizedContentUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedAudioContentPath + this.protectedAudioCategorizedContent;
      },

      get protectedAlbumImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedImageContentPath + this.protectedImageAlbumsContent;
      },
      get protectedCategoriesImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedImageContentPath + this.protectedImageCategoriesContent;
      },

      get protectedTracksDataUrl() {
        return this.serverUrl + this.protectedPath + this.protectedDataContentPath + this.protectedDataTracksContent;
      },

      get protectedCategoriesDataUrl() {
        return this.serverUrl + this.protectedPath + this.protectedDataContentPath + this.protectedDataCategoriesContent;
      },
      get protectedLanguagesImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.protectedPath + this.protectedImageContentPath + this.protectedImageLanguagesContent;
      },
      

      get nonProtectedAlbumImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedImageContentPath + this.protectedImageAlbumsContent +"optim/";
      },
      get nonProtectedLogoVideoUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedVideoContentPath + this.nonProtectedVideoLogoPath + this.nonProtectedAnimaterdVideoLogoPath;
      },
      get nonProtectedLogoImageUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedImageContentPath + this.nonProtectedLogoImagePath;
      },
      get nonProtectedSocialMediaIconsUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedImageContentPath + this.nonProtectedSocialMediaIcons;
      },

      get nonProtectedCategoriesImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedImageContentPath + this.protectedImageCategoriesContent;
      },

      get nonProtectedLanguagesImagesUrl() {
        // Calculate the authorization property using the encode function
        return this.serverUrl + this.nonProtectedPath + this.prod + this.nonProtectedImageContentPath + this.protectedImageLanguagesContent;
      }


  };
  
  const mapSiteMapPageToContentType = (stateData,siteMapPage) => {

    console.log("mapSiteMapPageToContentType ");
        
    if (siteMapPage??false)
      if(siteMapPage["Content-Type"]??false){
        console.log("Content-Type: ",siteMapPage["Content-Type"]);
        var mappedContent;

        switch (siteMapPage["Content-Type"]) {
          case "Narrated Content": 
          {
            if (stateData??false)
              if (stateData.Categories??false) {
                console.log("stateData.Categories: ", stateData.Categories);
                mappedContent = getNarratedContentCategories(stateData.Categories)
                console.log("DataManagement -> getNarratedContentCats -> ",mappedContent );
              } else {mappedContent = null};
            break;
            }

          case "Music": 
          {
            if (stateData??false)
              if (stateData.Categories??false) {
                console.log("stateData.Categories: ", stateData.Categories);
                mappedContent = getMusicCategories(stateData.Categories)
                console.log("DataManagement -> getMusicCats -> ",mappedContent );
              } else {mappedContent = null};
            break;
          }
          

          case "Technology": 
          {
            if (stateData??false) 
              if (stateData.Technology??false)
                if (stateData.Technology.Categories){mappedContent = stateData.Technology.Categories;} else {mappedContent = null};
            break;
          }
          case "Informational": 
          {
            mappedContent = null;
            break;
          }
          
          default:
            {
            mappedContent = null;
            break;  
          }
        }

        return mappedContent;

      }else{
       console.log("SiteMap property or value missing");
       return null; 
      }
     
  }


  const information = {
    appName: 'Serenart',
    version: '1.0.0',
  };
  
  export { information,
           protectedPaths,
           secure,
           mapSiteMapPageToContentType,
            };

  
